import React from 'react';
import HeaderInfo from '../shared/HeaderInfo';
import { Link } from 'react-router-dom';
import WorksComponent from '../works/WorksComponent';
import { FaEye, FaLink } from 'react-icons/fa'
import { urlBase } from '../../utils/API'
import "../../styles/works.css"
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
const Works = () => {
    const projectsData = useSelector((state) => state.projectRed)
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const completedProjects = projectsData?.filter(project => project.status === "completed")
    return (
        <div style={{ marginTop: "100px", marginBottom: "20px" }}>
            <HeaderInfo title={`${HomePage.works[lang]}`} info={`${HomePage.ideas[lang]}`} />
            <div className='container'>
                <div className='row justify-content-between align-items-center'>
                    <div className='row g-4 mt-5'>
                        {completedProjects?.map((project) => (
                            <div className='col-lg-6 col-md-6 col-sm-12' key={project._id}>
                                <div className='project-card'
                                    style={{
                                        backgroundImage: `url(${urlBase}/static-uploads/${project.coverImage})`
                                    }}>
                                    <div className='project-overlay'>
                                        <div className='project-icons'>
                                            <div className='icon-wrapper'>
                                                <FaEye size={24} />
                                            </div>
                                            <div className='icon-wrapper'>
                                                <FaLink size={24} />
                                            </div>
                                        </div>
                                        <h4 className='project-title'>{project.name}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='text-center mt-5'>
                <Link style={{ textDecoration: "none" }}>
                    {HomePage.watchMore[lang]}
                </Link>
            </div>
        </div>
    );
}

export default Works;
