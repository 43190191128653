import { CREATE_ACCOUNT, DELETE_ACCOUNT, GET_ALL_CONTACTS, UPDATE_CONTACT, } from "../actions/type/Type";



export const contactRed = (state = [], action) => {
    switch (action.type) {
        case CREATE_ACCOUNT:
            return state = [...state, action.data];
        case GET_ALL_CONTACTS:
            return state = action.data
        case UPDATE_CONTACT:
            return state = state.map(s => {
                if (s._id === action?.data?._id) {
                    return action.data
                }
                return s
            })
        case DELETE_ACCOUNT:
            return state = state.filter(s => s._id !== action.id)
        default:
            return state;
    }
};




