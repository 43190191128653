import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { Create_New_Account, get_All_Contacts } from '../../../actions/contactsAct';
import { ErrorMsg, success } from '../../shared/Modules/Toast';

const AddContact = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");
    const [whatsApp, setWhatsApp] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);

    const serviceRed = useSelector((state) => state.serviceReducer);
    const dispatch = useDispatch();
    const handleAllContacts = async () => {
        try {
            const res = await dispatch(get_All_Contacts());
            console.log(res)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        handleAllContacts();
    }, [])
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handlePhone = (valuePhone) => setPhone(valuePhone);
    const handleWhatsApp = (valuePhone) => setWhatsApp(valuePhone);

    const handleAddService = (event) => {
        const serviceId = event.target.value;
        const serviceName = serviceRed.find((service) => service._id === serviceId)?.name;

        if (!selectedServices.some((s) => s._id === serviceId)) {
            setSelectedServices([...selectedServices, { _id: serviceId, name: serviceName }]);
        }
    };

    const handleRemoveService = (serviceId) => {
        setSelectedServices(selectedServices.filter((service) => service._id !== serviceId));
    };

    const handleAddContact = async () => {
        setLoading(true);
        try {
            const res = await dispatch(Create_New_Account({
                name: name,
                email: email,
                message: message,
                phone: `+${phone}`,
                whatsapp: `+${whatsApp}`,
                service: selectedServices.map((service) => service._id),
            }));
            setLoading(false);
            if (res?.status === 201) {
                success("Contact Added Successfully");
                handleClose();
            }
        } catch (e) {
            const errorMessages = e?.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                // Handle array of error messages
                errorMessages.forEach(message => {
                    ErrorMsg(message); // Assuming you have an error toast function
                });
            } else {
                // Handle single error message
                ErrorMsg(errorMessages);
            }
            setLoading(false);
        } finally {
            setLoading(false)
        }
    };

    return (
        <div>
            <button className="btn save" onClick={handleShow}>Add Contact</button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Message</label>
                            <input
                                type="text"
                                className="form-control"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Whatsapp</label>
                            <PhoneInput
                                country="eg"
                                value={whatsApp}
                                onChange={handleWhatsApp}
                                className="inputs"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Phone</label>
                            <PhoneInput
                                country="eg"
                                value={phone}
                                onChange={handlePhone}
                                className="inputs"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Select Services</label>
                            <select className="form-control" onChange={handleAddService}>
                                <option hidden>Choose Service</option>
                                {serviceRed?.map((service) => (
                                    <option key={service._id} value={service._id}>{service.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-3">
                            <label>Selected Services</label>
                            <ul className="list-group">
                                {selectedServices.map((service) => (
                                    <li key={service._id} className="list-group-item d-flex justify-content-between align-items-center">
                                        {service.name}
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleRemoveService(service._id)}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn cancel" onClick={handleClose}>Cancel</button>
                    <button className="btn save" onClick={handleAddContact} disabled={loading}>
                        {loading ? "Loading..." : "Add"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddContact;
