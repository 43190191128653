import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/layout/PageContainer'
import HomeHeader from '../../components/Home/HomeHeader'
import Services from '../../components/Home/Services'
import Vision from '../../components/Home/Vision'
import Works from '../../components/Home/Works'
import Comment from '../../components/Home/Comment'
import Partners from '../../components/Home/Partners'
import Products from '../../components/Home/Products'
import ProjectOwner from '../../components/Home/ProjectOwner'
import { useLocation } from 'react-router-dom'

const HomePage = () => {
    return (
        <div>
            <PageContainer>
                <HomeHeader />
                <Services />
                <Vision />
                <Works />
                <Comment />
                <Partners />
                <Products />
                <ProjectOwner />
            </PageContainer>
        </div>
    )
}

export default HomePage
