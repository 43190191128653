import React, { useEffect, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { Link, Outlet } from 'react-router-dom'
import "./style/dash.css"
import { FaUser, FaShoppingCart, FaCog, FaHeadset, FaBars } from 'react-icons/fa'
import HeaderClient from './HeaderClient'
import { IoMdNotifications } from "react-icons/io";

import { AiOutlineMessage } from "react-icons/ai";
import { HomePage } from '../../../translation/trans'
import { useSelector } from 'react-redux'

const DashboardClient = () => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const navItems = [
        { path: "", icon: <FaUser size={20} />, text: `${HomePage.profile[lang]}` },
        { path: "messages", icon: <AiOutlineMessage size={20} />, text: `${HomePage.messages[lang]}` },
        { path: "notifications", icon: <IoMdNotifications size={20} />, text: `${HomePage.notifications[lang]}` },
        { path: "service", icon: <FaHeadset size={20} />, text: `${HomePage.service[lang]}` },
        { path: "orders", icon: <FaShoppingCart size={20} />, text: `${HomePage.orders[lang]}` },
        { path: "settings", icon: <FaCog size={20} />, text: `${HomePage.settings[lang]}` },
        { path: "support", icon: <FaHeadset size={20} />, text: `${HomePage.support[lang]}` },
    ];
    const [user, setUser] = useState(null);
    useEffect(() => {

        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            } catch (error) {
                console.log("Invalid user data in localStorage");
                localStorage.removeItem("user");
            }
        }
    }, []);
    return (
        <div className="dashboard-wrapper">
            <PageContainer>
                <BreadCrumb mainTitle={`${HomePage.home[lang]}`} secondTitle={`${HomePage.dashboard[lang]}`} />
                <div className="dashboard-container" dir={dir}>
                    <div className={`dashboard-sidebar ${collapsed ? 'collapsed' : ''}`} dir={dir}>
                        <button className="sidebar-toggle" onClick={toggleSidebar}>
                            <FaBars size={20} />
                        </button>
                        
                        <nav className="mt-5">
                            
                            <ul>
                                {navItems.map((item, index) => (
                                    <li key={index}>
                                        <Link to={`/dashboard/${user?._id}/client/${item?.path}`}>
                                            <div className="nav-item">
                                                <span className='icon'>{item.icon}</span>
                                                <span className="nav-text">{item.text}</span>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="dashboard-content">
                        <Outlet />
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default DashboardClient
