import { createTestimonial, deleteTestmonial, getAllTestmonial } from "../utils/testimonial"
import { CREATE_TESTIMONIAL, DELETE_TESTIMONIAL, GET_ALL_TESTMONIAL } from "./type/Type"

const create_testimonial = (data) => {
    return {
        type: CREATE_TESTIMONIAL,
        data
    }
}


export const Create_Testimonial_Act = (data) => {
    return (dispatch) => {

        return createTestimonial(data).then(res => {
            console.log(res)
            if (res.status === 201) {
                dispatch(create_testimonial(res?.data))
            }
            return res
        })
    }
}


const get_all_testimonial = (data) => {
    return {
        type: GET_ALL_TESTMONIAL,
        data
    }
}

export const Get_All_Testimonial = () => {
    return (dispatch) => {

        return getAllTestmonial().then(res => {
            console.log(res)
            if (res.status === 200) {
                dispatch(get_all_testimonial(res?.data))
            }
            return res
        })
    }
}


const delete_testmonial = (id)=>{
    return{
        type:DELETE_TESTIMONIAL,
        id
    }
}

export const Delete_Testimonial = (id) => {
    return (dispatch) => {

        return deleteTestmonial(id).then(res => {
            console.log(res)
            // if (res.status === 200) {
                dispatch(delete_testmonial(id))
            // }
            return res
        })
    }
}