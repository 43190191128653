import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Get_All_project } from "../../../actions/projectsAction"
import { MdDelete } from "react-icons/md";
import DeleteProject from './DeleteProject';
import ModalUpdate from './ModalUpdate';
import { urlBase } from '../../../utils/API';
import ModalAddStage from './ModalAddStage';
import ModalAddPaymentStage from './ModalAddPaymentStage';
import { MdModeEditOutline } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import AddTestimonial from './AddTestimonial ';
import ShowTestimonial from './ShowTestimonial';
import { Get_All_Testimonial } from '../../../actions/testimonialAct';

const Projects = () => {
    const [selectedDescription, setSelectedDescription] = useState(null);
    const dispatch = useDispatch();

    const handleGetProjects = async () => {
        try {
            // const res = await dispatch(Get_All_project());
            // console.log(res)
            await dispatch(Get_All_Testimonial())
        } catch (e) {
            console.log(e)
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return dateString.split('T')[0];
    }

    useEffect(() => {
        handleGetProjects();
    }, [])

    const projectsData = useSelector((state) => state.projectRed);

    const handleDescriptionClick = (description) => {
        setSelectedDescription(selectedDescription === description ? null : description);
    }


    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showTestimnial, setShowTestimnial] = useState(false);
    const [showTestimnialModal, setShowTestimnialModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [project, setProject] = useState(null);

    const handleShowUpdateModal = (project) => {
        setSelectedProject(project);
        setShowUpdateModal(true);
    };

    const handleAddTest = (pro) => {
        setProject(pro)
        setShowTestimnial(true)
    }
    const handleShowTest = (pro) => {
        setProject(pro)
        setShowTestimnialModal(true)
    }

    return (
        <div>
            <table className="tableReq table-table-responsive">
                <thead className="p-5">
                    <th>صورة المشروع</th>
                    <th>اسم المشروع</th>
                    <th>وصف المشروع</th>
                    <th>تاريخ البدايه</th>
                    <th>تاريخ النهايه</th>
                    <th>حالة المشروع</th>
                    <th>
                        Add Testimonial
                    </th>
                    <th>Add Stage</th>
                    <th>Add Payment Stage</th>
                    <th>تعديل</th>
                    <th>حذف</th>
                </thead>
                <tbody className="p-5">
                    {
                        projectsData?.map((pro) => {
                            return (
                                <tr key={pro?._id} className="mt-3 p-3">
                                    <td className="table-image-cell">
                                        <img
                                            src={`${urlBase}/static-uploads/${pro?.coverImage}`}
                                            alt="service icon"
                                            className="table-image"
                                        />
                                    </td>
                                    <td>{pro?.name}</td>
                                    <td style={{ position: 'relative', cursor: 'pointer' }} onClick={() => handleDescriptionClick(pro?.description)}>
                                        {pro?.description?.substring(0, 10)}...
                                        {selectedDescription === pro?.description && (
                                            <div style={{
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                padding: '10px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                zIndex: 1000,
                                                minWidth: '200px',
                                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                                color: "black"
                                            }}>
                                                {pro?.description}
                                            </div>
                                        )}
                                    </td>
                                    <td>{formatDate(pro?.startDate)}</td>
                                    <td>{formatDate(pro?.endDate)}</td>
                                    <td>{pro?.status}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Testimonial
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <button className='btn' onClick={() => { handleAddTest(pro) }}>Add Testimonial</button>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <button className='btn' onClick={() => { handleShowTest(pro) }}>Show Testimonial</button>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>
                                        <ModalAddStage pro={pro} />
                                    </td>
                                    <td>
                                        <ModalAddPaymentStage pro={pro} />
                                    </td>
                                    <td>
                                        <MdModeEditOutline
                                            onClick={() => handleShowUpdateModal(pro)}
                                            size={"25px"}
                                            color="white"
                                            cursor={"pointer"}
                                        />
                                        {/* <ModalUpdate pro={pro} /> */}
                                    </td>
                                    <td>
                                        <DeleteProject proId={pro?._id} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <AddTestimonial show={showTestimnial} handleClose={() => setShowTestimnial(false)} project={project} />
            <ShowTestimonial show={showTestimnialModal} handleClose={() => setShowTestimnialModal(false)} project={project} />
            <ModalUpdate
                show={showUpdateModal}
                setShow={setShowUpdateModal}
                pro={selectedProject}
            />
        </div>
    )
}

export default Projects
