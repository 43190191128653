
import axios from "axios";
import { urlBase } from "./API";

// Create Testmonial
export const createTestimonial = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/testimonial`,
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}



// Get all Testmonial
export const getAllTestmonial = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/testimonial`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// Delete Testmonial
export const deleteTestmonial = (id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/testimonial/${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}