import { createService, getServices, deleteService, serviceUpdate } from "../utils/Service"
import { CREATE_NEW_SERVICE, DELETE_SINGLE_SERVICE, GET_ALL_SERVICE, UPDATE_SERVICE } from "./type/Type"


// Create Service
const create_service = (data) => {
    return {
        type: CREATE_NEW_SERVICE,
        data
    }
}
export const Create_New_service = (data) => {
    return (dispatch) => {

        return createService(data).then(res => {

            if (res.status === 201) {
                dispatch(create_service(res?.data))
            }
            return res
        })
    }
}


// Get Service
const get_all_service = (data) => {
    return {
        type: GET_ALL_SERVICE,
        data
    }
}
export const Get_All_services = (data) => {
    return (dispatch) => {

        return getServices().then(res => {

            if (res.status === 200) {
                dispatch(get_all_service(res?.data))
            }
            return res
        })
    }
}



// Delete Service
const delete_single_service = (id) => {
    return {
        type: DELETE_SINGLE_SERVICE,
        id
    }
}
export const Delete_Single_Services = (id) => {
    return (dispatch) => {

        return deleteService(id).then(res => {

            // if (res.status === 200) {
            dispatch(delete_single_service(id))
            // }
            return res
        })
    }
}



// Update Service

const update_service = (data) => {
    return {
        type: UPDATE_SERVICE,
        data
    }
}

export const Update_Single_Services = (data, id) => {
    return (dispatch) => {

        return serviceUpdate(data, id).then(res => {

            // if (res.status === 200) {
            dispatch(update_service(res?.data))
            // }
            return res
        })
    }
}