import React, { useState } from "react";
import { MdDashboard } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { FaMoneyBills } from "react-icons/fa6";
import { BsMicrosoftTeams } from "react-icons/bs";
import { FaFileContract } from "react-icons/fa";
import { IoNotificationsSharp } from "react-icons/io5";
import { LuMessageSquare } from "react-icons/lu";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdOutlineHelp } from "react-icons/md";
import { FaUserGroup } from "react-icons/fa6";
import "./sidNav.css";
import { Link, NavLink } from "react-router-dom";
// import { Log_Out } from "../../actions/signin-up";
import { useDispatch } from "react-redux";
const Tap = ({ index, viewComponent, setView, name }) => {
    return (<>
        <li className="menu-item" onClick={() => setView(viewComponent[index])}>{name}</li>
        <hr />
    </>)
}
const SidNav = ({ minimized }) => {

    const dispatch = useDispatch()
    const [iconsize, setIconSize] = useState(25);
    const [iconColor, setIconColor] = useState('#4B75C0')
    const taps = [
        {
            name: "لوحة التحكم",
            path: "",
            icon: <MdDashboard
                size={iconsize}
                color={iconColor} />
        },
        {
            name: "الموظفين", path: "employee",
            icon: <FaUserGroup size={iconsize}
                color={iconColor} />
        },
        {
            name: "تعديل الملف الشخصي",
            path: "packages",
            icon: <IoMdSettings size={iconsize} color={iconColor} />
        },
        {
            name: "معلومات الفواتير", path: "team",
            icon: <FaMoneyBills size={iconsize}
                color={iconColor} />
        },
        {
            name: "المشاريع وفريق  العمل", path: "projects",
            icon: <BsMicrosoftTeams size={iconsize}
                color={iconColor} />
        },
        {
            name: "المدونه", path: "blogs-system",
            icon: <FaFileContract size={iconsize}
                color={iconColor} />
        },
        {
            name: "الاشعارات", path: "profile",
            icon: <IoNotificationsSharp size={iconsize}
                color={iconColor} />
        },
        {
            name: "الرسائل", path: "profile",
            icon: <LuMessageSquare size={iconsize}
                color={iconColor} />
        },
        {
            name: "الدعم الفني", path: "profile",
            icon: <RiCustomerService2Fill size={iconsize}
                color={iconColor} />
        },
        {
            name: "مركز المساعده", path: "profile",
            icon: <MdOutlineHelp size={iconsize}
                color={iconColor} />
        },


    ];


    return (
        <>
            <div className="sidnav ">
                {/* {!minimized && <img src={Logo} width={'50px'} className="logo" />} */}
                <ul className="side-menu px-2">
                    {taps.map((t, index) => (
                        <>
                            <li key={index} className="menu-item">
                                <NavLink className="side-link" to={t.path}><span className="iconColor">{t.icon}</span>{!minimized ? t.name : ''}</NavLink>
                            </li>
                        </>
                    ))}
                    {/* <li className="menu-item">
                    <span className="side-link" onClick={()=>dispatch(Log_Out())}>{!minimized? <>Logout<FaPowerOff size={iconsize}  
                color={iconColor}/></>:<FaPowerOff size={iconsize}  
                color={iconColor}/>}</span>
                </li>  */}
                </ul>
            </div>
        </>
    )
}
export default SidNav;