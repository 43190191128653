import React, { useEffect, useState } from 'react'
import "../../../styles/main.css"
import { useNavigate } from 'react-router-dom';
const HeaderMain = ({ title,
    percentage,
    duration,
    icon }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        const handleAuthChange = () => {
            const storedUser = localStorage.getItem("user");
            if (storedUser) {
                try {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                } catch (error) {
                    console.log("Invalid user data in localStorage");
                    localStorage.removeItem("user");
                }
            }
        };


        handleAuthChange();


    }, []);
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(`/dashboard/${user?._id}/contacts`)
    }
    return (
        <div className="minheaderdiv" onClick={handleNavigate}>
            <div style={{ lineHeight: "15px", padding: "10px" }}>
                <p>{title}</p>
                <p>{percentage}</p>
                <span style={{ fontSize: "10px" }}>{duration}</span>
            </div>
            <div>
                <p className="iconHeader">{icon}</p>
            </div>
        </div>
    )
}

export default HeaderMain
