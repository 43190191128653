import React, { useEffect, useState } from 'react';
import profile from "../../../assests/profile.png";
import { Modal, Button } from 'react-bootstrap';
import { FaCamera } from 'react-icons/fa';
import axios from 'axios';
import { updateProfile, urlBase } from '../../../utils/API';

const Headerdashboard = () => {
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState(null);
    const [profileImage, setProfileImage] = useState(profile);
    const [user, setUser] = useState(null);

    useEffect(() => {

        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            } catch (error) {
                console.log("Invalid user data in localStorage");
                localStorage.removeItem("user");
            }
        }
    }, []);

    useEffect(() => {
        setProfileImage(user?.coverImage)
    }, [user])

    const handleCameraClick = () => {
        setShowModal(true);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            const url = URL.createObjectURL(file);
            setProfileImage(url);  // Show selected image as preview
        }
    };

    const handleUpload = async () => {
        if (image) {
            const formData = new FormData();
            formData.append("file", image);
            try {
                const res = await axios.post(`${urlBase}/api/uploads/single`, formData);
                console.log('Upload successful:', res.data);
                if (res?.data?.filename) {
                    await updateProfile({
                        // ...user,
                        coverImage: res?.data?.filename
                    })
                }
                const updatedUser = {
                    ...user,
                    coverImage: res?.filename,
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                setUser(updatedUser);
                setShowModal(false);  // Close modal after upload
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    return (
        <div>
            <div className='header-client' style={{ position: 'relative' }}>
                <div className='profile'>
                    <img src={profileImage} alt="profile" className='profile-client' />
                    <FaCamera
                        onClick={handleCameraClick}
                        className="camera-icon"
                        style={{ position: 'absolute', bottom: '10px', right: '10px', cursor: 'pointer', fontSize: '1.5rem', color: '#555' }}
                    />
                </div>
            </div>

            {/* Modal for file upload */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="file" name="fileImage" id="fileImage" className="form-control" onChange={handleFileChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpload}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Headerdashboard;
