import { CREATE_TESTIMONIAL, DELETE_TESTIMONIAL, GET_ALL_TESTMONIAL } from "../actions/type/Type";




export const testimonialRed = (state = [], action) => {
    switch (action.type) {
        case CREATE_TESTIMONIAL:
            return state = [...state, action.data];
        case GET_ALL_TESTMONIAL:
            return state = action.data.testimonials
        // case UPDATE_SINGLE_BLOG:
        //     return state = state.map(s => {
        //         if (s._id === action?.data?._id) {
        //             return action.data
        //         }
        //         return s
        //     })
        case DELETE_TESTIMONIAL:
            return state = state.filter(s => s._id !== action.id)
        default:
            return state;
    }
};




