export const REGISTER_NEW_USER = "REGISTER_NEW_USER"
export const REGISTER_NEW_USER_ADMIN = "REGISTER_NEW_USER_ADMIN"
export const GET_ALL_USERS_ADMIN = "GET_ALL_USERS_ADMIN"

// Services
export const CREATE_NEW_SERVICE = "CREATE_NEW_SERVICE"
export const GET_ALL_SERVICE = "GET_ALL_SERVICE"
export const DELETE_SINGLE_SERVICE = "DELETE_SINGLE_SERVICE"
export const UPDATE_SERVICE = "UPDATE_SERVICE"


// Projects

export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT"
export const GET_PROJECTS = "GET_PROJECTS"
export const DEL_PROJECT = "DEL_PROJECT"
export const UPDATE_PROJECTS = "UPDATE_PROJECTS"

// Blogs
export const CREATE_NEW_BLOG = "CREATE_NEW_BLOG"
export const GET_ALL_BLOGS = "GET_ALL_BLOGS"
export const DEL_SINGLE_BLOG = "DEL_SINGLE_BLOG"
export const UPDATE_SINGLE_BLOG = "UPDATE_SINGLE_BLOG"

// Testimonials
export const CREATE_TESTIMONIAL = "CREATE_TESTIMONIAL"
export const GET_ALL_TESTMONIAL = "GET_ALL_TESTMONIAL"
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL"

// Contacts
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS"
export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const UPDATE_CONTACT = "UPDATE_CONTACT"