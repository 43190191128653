
import axios from "axios";
import { urlBase } from "./API";

// Create Blog
export const getAllContacts = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "GET",
        url: `${urlBase}/api/contacts`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}


// Add Contact
export const handleAddContact = (data) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "POST",
        url: `${urlBase}/api/contacts`,
        data:data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}

// Delete Contact
export const deleteContact = (id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/contacts/${id}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}


// Update Contact

// Delete Contact
export const updateContact = (data,id) => {
    const token = localStorage.getItem('token');
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/contacts/${id}`,
        data:data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

}