import React, { useEffect, useState } from 'react'
import AddEmployee from './AddEmployee'
import { useDispatch, useSelector } from 'react-redux';
import { Get_All_Users_Admin } from '../../../actions/signin-up';
import { Table } from 'react-bootstrap';
import Pagination from '../../../utils/Pagination';

const Employee = () => {

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        role: ''
    });
    const limit = 5;

    const getAllUsers = async (pageNumber) => {
        try {
            await dispatch(Get_All_Users_Admin( pageNumber, limit, filters));
        } catch (e) {
            console.log(e)
        }
    }



    useEffect(() => {
        getAllUsers(page, filters);
    }, [page, filters])

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
        setPage(1);
    }

    const users = useSelector((state) => state.UsersRed);
    return (
        <div className='mt-5'>
            <AddEmployee />

            <select name="role" onChange={handleFilterChange} value={filters.role} className='form-control mt-5 w-50'>
                    <option value="">All Roles</option>
                    <option value="admin">Admin</option>
                    <option value="client">client</option>
                    <option value="employee">employee</option>
                </select>
            <div className="mt-5 ">
                {users?.length > 0 ? (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>الاسم</th>
                                <th>الايميل</th>
                                <th>الوظيفه</th>
                                <th>التليقون</th>
                                <th>role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user, index) => (
                                <tr key={user?._id}>
                                    <td>{user?.name}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.job}</td>
                                    <td>{user?.phone}</td>
                                    <td>{user?.role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="text-center">
                        <h4>لا يوجد مستخدمين</h4>
                    </div>
                )}
            </div>
            <Pagination onPress={(pageNumber) => setPage(pageNumber)} countsPage={5} />
        </div>
    )
}

export default Employee
