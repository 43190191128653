import { GET_ALL_USERS_ADMIN, REGISTER_NEW_USER_ADMIN } from "../actions/type/Type";




export const UsersRed = (state = [], action) => {
    switch (action.type) {
        case REGISTER_NEW_USER_ADMIN:
            return state = [...state, action.data];
        case GET_ALL_USERS_ADMIN:
            return state = action.data
        // case UPDATE_SINGLE_BLOG:
        //     return state = state.map(s => {
        //         if (s._id === action?.data?._id) {
        //             return action.data
        //         }
        //         return s
        //     })
        // case DEL_SINGLE_BLOG:
        //     return state = state.filter(s => s._id !== action.id)
        default:
            return state;
    }
};




