import React from 'react'
import profileImage from '../../../assests/message.png'
import "./style/dash.css"
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translation/trans';
const Messages = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <button className='btn' style={{ color: "white", background: "#5480CD", borderRadius: "20px" }}>
                {HomePage.newMessage[lang]}
            </button>

            <div>
                <div className="chat-message" dir={dir}>
                    <div className="chat-time">3:28 AM</div>
                    <div className="chat-content">
                        <img src={profileImage} alt={"data"} className="chat-avatar" />
                        <div className="chat-text">
                            <div className="chat-name">منه محمد</div>
                            <div className="chat-message-text">مرحبا أود أن أرسل رسالة لك</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages
