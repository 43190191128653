import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import AddContact from './AddContact';
import DeleteContact from './DeleteContact';
import EditContact from './EditContact';

const Contact = () => {
    const contacts = useSelector((state) => state.contactRed);

    const [showDelet, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [project, setProject] = useState(false);

    const handleShowDelete = (c) => {
        setProject(c);
        setShowDelete(true)
    }
    const handleShowEdit = (c) => {
        setProject(c);
        setShowEdit(true)
    }
    return (
        <div>
            <div className='mt-3'>
                <AddContact />
            </div>
            <Table responsive className='mt-4'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>Phone</th>
                        <th>Whatsapp</th>
                        <th>Services</th>
                        <th>Status</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts?.map((c, i) => {
                        return (
                            <tr key={i}>
                                <td>{c?.name}</td>
                                <td>{c?.email}</td>
                                <td>{c?.message}</td>
                                <td>{c?.phone}</td>
                                <td>{c?.whatsapp}</td>
                                <td>
                                    {Array.isArray(c?.service)
                                        ? c?.service.join(', ')
                                        : c?.service}
                                </td>
                                <td>{c?.status}</td>
                                <td>
                                    <CiEdit onClick={() => handleShowEdit(c)} />
                                </td>
                                <td>
                                    <RiDeleteBinLine onClick={() => handleShowDelete(c)} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <DeleteContact show={showDelet} handleClose={() => setShowDelete(false)} pro={project} />
            <EditContact show={showEdit} handleClose={() => setShowEdit(false)} pro={project} />
        </div>
    )
}

export default Contact
