


import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { Update_Single_Contact } from '../../../actions/contactsAct';
import { success } from '../../shared/Modules/Toast';
const EditContact = ({ show, handleClose, pro }) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");
    const [whatsApp, setWhatsApp] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const serviceRed = useSelector((state) => state.serviceReducer);

    const [initialPhone, setInitialPhone] = useState("");
    const [initialWhatsApp, setInitialWhatsApp] = useState("");

    useEffect(() => {
        setName(pro?.name || "");
        setEmail(pro?.email || "");
        setMessage(pro?.message || "");
        setPhone(pro?.phone || "");
        setWhatsApp(pro?.whatsapp || "");

        // حفظ القيم الأصلية للـ phone و whatsapp
        setInitialPhone(pro?.phone || "");
        setInitialWhatsApp(pro?.whatsapp || "");

        const mappedServices = pro?.service?.map((serviceId) => {
            return serviceRed?.find((service) => service._id === serviceId) || { _id: serviceId, name: "Unknown" };
        });
        setSelectedServices(mappedServices || []);
    }, [pro, serviceRed]);

    const handlePhone = (valuePhone) => setPhone(valuePhone);
    const handleWhatsApp = (valuePhone) => setWhatsApp(valuePhone);

    const handleAddService = (event) => {
        const serviceId = event.target.value;
        const serviceName = serviceRed.find((service) => service._id === serviceId)?.name;

        if (!selectedServices.some((s) => s._id === serviceId)) {
            setSelectedServices([...selectedServices, { _id: serviceId, name: serviceName }]);
        }
    };

    const handleRemoveService = (serviceId) => {
        setSelectedServices(selectedServices.filter((service) => service._id !== serviceId));
    };

    const dispatch = useDispatch();

    const handleUpdate = async () => {
        setLoading(true);
        try {
            // إضافة "+" فقط إذا تم تعديل الرقم
            const updatedPhone = phone !== initialPhone ? `+${phone}` : phone;
            const updatedWhatsApp = whatsApp !== initialWhatsApp ? `+${whatsApp}` : whatsApp;

            const res = await dispatch(Update_Single_Contact({
                name: name,
                email: email,
                message: message,
                phone: updatedPhone,
                whatsapp: updatedWhatsApp,
                service: selectedServices.map((service) => service._id),
            }, pro?._id));
            if(res?.status === 200){
                success(`Updated contact`)
                handleClose();
            }
        } catch (e) {
            console.error(e);
        }finally{
            setLoading(false)
        }
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Update Contact</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <div>
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Message</label>
                            <input
                                type="text"
                                className="form-control"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Whatsapp</label>
                            <PhoneInput
                                country="eg"
                                value={whatsApp}
                                onChange={handleWhatsApp}
                                className="inputs"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Phone</label>
                            <PhoneInput
                                country="eg"
                                value={phone}
                                onChange={handlePhone}
                                className="inputs"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="mt-3">
                            <label>Select Services</label>
                            <select className="form-control" onChange={handleAddService}>
                                <option hidden>Choose Service</option>
                                {serviceRed?.map((service) => (
                                    <option key={service._id} value={service._id}>{service.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-3">
                            <label>Selected Services</label>
                            <ul className="list-group">
                                {selectedServices?.map((service) => (
                                    <li key={service._id} className="list-group-item d-flex justify-content-between align-items-center">
                                        {service.name}
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleRemoveService(service._id)}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn cancel' onClick={handleClose}>Cancel</button>
                    <button className='btn save' onClick={handleUpdate} disabled={loading}>
                        {loading ? "Updating..." : "Update"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EditContact;
