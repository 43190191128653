import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { Delete_Single_Account } from '../../../actions/contactsAct';

const DeleteContact = ({ show, handleClose, pro }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const handleDeleteContact = async () => {
        try {
            setLoading(true);
            const res = await dispatch(Delete_Single_Account(pro?._id))
            handleClose();
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Delete Contact</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Do You Want Delete ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn cancel'>Cancel</button>
                    <button className='btn save' onClick={handleDeleteContact} disabled={loading}>
                        {loading ? "Deleting..." : "Delete"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteContact
