import { deleteContact, getAllContacts, handleAddContact, updateContact } from "../utils/contacts"
import { CREATE_ACCOUNT, DELETE_ACCOUNT, GET_ALL_CONTACTS, UPDATE_CONTACT } from "./type/Type"

const get_contacts = (data) => {
    return {
        type: GET_ALL_CONTACTS,
        data
    }
}
export const get_All_Contacts = () => {
    return (dispatch) => {

        return getAllContacts().then(res => {
            if (res.status === 200) {
                dispatch(get_contacts(res?.data))
            }
            return res
        })
    }
}


const create_account = (data) => {
    return {
        type: CREATE_ACCOUNT,
        data
    }
}
export const Create_New_Account = (data) => {
    return (dispatch) => {

        return handleAddContact(data).then(res => {
            if (res.status === 201) {
                dispatch(create_account(res?.data))
            }
            return res
        })
    }
}
const delete_account = (id) => {
    return {
        type: DELETE_ACCOUNT,
        id
    }
}
export const Delete_Single_Account = (id) => {
    return (dispatch) => {

        return deleteContact(id).then(res => {
            dispatch(delete_account(id))
            return res
        })
    }
}


// Update Contact
const update_contact = (data,id) => {
    return {
        type: UPDATE_CONTACT,
        data,
        id
    }
}
export const Update_Single_Contact = (data,id) => {
    return (dispatch) => {
        return updateContact(data,id).then(res => {
            console.log(res)
            if(res?.status === 200){
                dispatch(update_contact(res?.data))
            }
            return res
        })
    }
}