import React, { useState } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import { FaStar } from "react-icons/fa6";
import { Create_Testimonial_Act } from '../../../actions/testimonialAct';
import { useDispatch } from 'react-redux';
import { success } from '../../shared/Modules/Toast';

const AddTestimonial = ({ show, handleClose, project }) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reviewType, setReviewType] = useState("");
    const [reviewContent, setReviewContent] = useState("")

    const handleStarClick = (rate) => {
        setRating(rate);
    };

    const handleToggleActive = () => {
        setIsActive((prev) => !prev);
    };
    const dispatch = useDispatch();


    const handleAddTestimonial = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Create_Testimonial_Act({
                client: project?.client?._id,
                reviewType: reviewType,
                review:reviewContent,
                rate: rating,
                project: project?._id,
                active: isActive
            }))
            if(res?.status === 201){
                success("Testimonial Added Successfully")
                handleClose()
            }
        } catch (e) {
            console.log(e)
        }finally{
            setLoading(false)
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add Testimonial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className=''>
                            <label>Review Type</label>
                            <select className='form-control' value={reviewType} onChange={(e) => setReviewType(e.target.value)}>
                                <option hidden>Choose Review Type</option>
                                <option value="comment">Comment</option>
                                <option value="audio">Audio</option>
                                <option value="video">Video</option>
                            </select>
                        </div>
                        <div className='mt-3'>
                            <label>Review Content</label>
                            <input type="text" className='form-control' name="reviewContent" id="reviewContent" value={reviewContent} onChange={(e) => setReviewContent(e.target.value)} />
                        </div>
                        <div className='mt-3'>
                            <label>Rate the Testimonial</label>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <FaStar
                                        key={star}
                                        size={24}
                                        style={{ cursor: 'pointer' }}
                                        color={star <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                                        onClick={() => handleStarClick(star)}
                                        onMouseEnter={() => setHover(star)}
                                        onMouseLeave={() => setHover(null)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label>Status</label>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={isActive ? 'Active' : 'Inactive'}
                                checked={isActive}
                                onChange={handleToggleActive}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn cancel' onClick={handleClose}>Cancel</button>
                    <button className='btn save' onClick={handleAddTestimonial}
                        disabled={loading}

                    >
                        {loading ? <Spinner /> : 'Add Testimonial'}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddTestimonial;
