// import { getAuthedUser, intializeUser, logOut, register,update_user } from "../utils/API";

import { getAllUsers, registerUser, registerUserAdmin } from "../utils/API"
import { GET_ALL_USERS_ADMIN, REGISTER_NEW_USER ,REGISTER_NEW_USER_ADMIN} from "./type/Type"







const register_user = (data) => {
  return {
    type: REGISTER_NEW_USER,
    data
  }
}
export const Register_New_User = (data) => {
  return (dispatch) => {
    
    return registerUser(data).then(res => {
      // if (res.status === 200) {
      //   dispatch(register_user(data))
      // }
      return res
    })
  }
} 

// Create User
const register_user_admin = (data) => {
  return {
    type: REGISTER_NEW_USER_ADMIN,
    data
  }
}
export const Register_New_User_Admin = (data) => {
  return (dispatch) => {
    
    return registerUserAdmin(data).then(res => {
      
      if (res.status === 201) {
        dispatch(register_user_admin(res?.data))
      }
      return res
    })
  }
} 


// Get ALL Users
const get_users_admin = (data) => {
  return {
    type: GET_ALL_USERS_ADMIN,
    data
  }
}
export const Get_All_Users_Admin = ( page, limit, filters) => {
  return (dispatch) => {
    
    return getAllUsers( page, limit, filters).then(res => {
      if (res.status === 200) {
        dispatch(get_users_admin(res?.data))
      }
      return res
    })
  }
} 