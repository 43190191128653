import React, { useEffect, useState } from 'react'
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import "./style/dash.css"
import { IoIosArrowRoundBack } from "react-icons/io";
import line from "../../../assests/line.png"
import { useDispatch, useSelector } from 'react-redux';
import { GetMyProjects } from '../../../utils/projects';
import { urlBase } from '../../../utils/API';
import { HomePage } from '../../../translation/trans';
const ServiceDashboard = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const [projects, setProject] = useState([]);

    const handleGetMyProject = async () => {
        try {
            const res = await GetMyProjects();
            console.log("DATADADADASD", res)
            if (res?.status === 200) {
                setProject(res?.data)
            }
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {



        handleGetMyProject();
    }, [])
    return (
        // <div>
        //     <div className='' style={{ color: "#456FB8" }}>
        //         <span>
        //             <FaCheckCircle color="#2A830A" />
        //         </span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>تم طلب الخدمه</span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>بتاريخ 12-10-2024</span>
        //         <img src={line} alt="line" />
        //         <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>نوع الخدمة : تطوير المواقع </span>
        //         <img src={line} alt="line" />
        //         <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>التكلفة : 200 دولار</span>
        //     </div>
        //     <div className='mt-5' style={{ color: "#456FB8" }}>
        //         <span>
        //             <FaCheckCircle color="#2A830A" />
        //         </span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>جاري إعداد الخدمات</span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>بتاريخ 12-10-2024</span>
        //         <img src={line} alt="line" />
        //         <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>تم التواصل مع د علي</span>
        //     </div>
        //     <div className='mt-5' style={{ color: "#456FB8" }}>
        //         <span>
        //             <FaCheckCircle color="#2A830A" />
        //         </span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>جاري العمل على</span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>بتاريخ 12-10-2024</span>
        //         <img src={line} alt="line" />
        //         <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>برمجة الموقع</span>
        //     </div>
        //     <div className='mt-5' style={{ color: "#456FB8" }}>
        //         <span>
        //             <FaCheckCircle color="#2A830A" />
        //         </span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>تم الإنتهاء من</span>
        //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //         <span>بتاريخ 12-10-2024</span>
        //         <img src={line} alt="line" />
        //         <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>تصميم الموقع</span>
        //     </div>
        // </div>
        <div>
            {projects.map((project) => (
                <div key={project._id} className="project-container mb-5">
                    <div className="project-header" style={{ display: "flex", alignItems: "center", gap: "20px", marginBottom: "20px" }}>
                        <img
                            src={`${urlBase}/static-uploads/${project.coverImage}`}
                            alt={project.name}
                            style={{ width: "100px", height: "100px", borderRadius: "8px", objectFit: "cover" }}
                        />
                        <h4 style={{ color: "#456FB8", margin: 0 }}>{project.name}</h4>
                    </div>

                    <div className='mb-4' style={{ color: "#456FB8" }}>
                        <div className="dates-section">
                            <span>
                                <FaCheckCircle color="#2A830A" />
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{HomePage.startDate[lang]}: {new Date(project.startDate).toLocaleDateString()}</span>
                            <img src={line} alt="line" />
                            <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>
                                {HomePage.endDate[lang]}: {new Date(project.endDate).toLocaleDateString()}
                            </span>
                            <img src={line} alt="line" />
                            <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>
                                {HomePage.finalDate[lang]}: {new Date(project.deadline).toLocaleDateString()}
                            </span>
                        </div>
                    </div>

                    {/* Stages */}
                    {project.stages?.map((stage, index) => (
                        <div key={index} className='mt-3' style={{ color: "#456FB8" }}>
                            <span>
                                {stage.status === "completed" ?
                                    <FaCheckCircle color="#2A830A" /> :
                                    <FaRegCircle color="#456FB8" />
                                }
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{stage.name}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{HomePage.date[lang]} {new Date(stage.date).toLocaleDateString()}</span>
                            <img src={line} alt="line" />
                            <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>
                                {stage.description}
                            </span>
                        </div>
                    ))}

                    {/* Payment Stages */}
                    {project.paymentStages?.map((payment, index) => (
                        <div key={index} className='mt-3' style={{ color: "#456FB8" }}>
                            <span>
                                {payment.status === "paid" ?
                                    <FaCheckCircle color="#2A830A" /> :
                                    <FaRegCircle color="#456FB8" />
                                }
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{payment.name}</span>
                            <img src={line} alt="line" />
                            <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>
                                {payment.description}
                            </span>
                            <img src={line} alt="line" />
                            <span style={{ display: "inline-block", padding: "10px", borderRadius: "10px", background: "#4872BB", color: "white" }}>
                                {HomePage.price[lang]}: {payment.price} {payment.discount && `(خصم: ${payment.discount})`}
                            </span>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default ServiceDashboard
