import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ServiceComponent from './ServiceComponent';
import { ErrorMsg } from '../shared/Modules/Toast';
import { getSingleServiceData } from '../../utils/Service';
import BreadCrumb from '../shared/BreadCrumb';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux';
const DetailsService = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const params = useParams();
    const [singleService, setSingleService] = useState({});

    const handleGetSingleService = async (id) => {
        try {
            const service = await getSingleServiceData(params?.id)

            if (service?.status === 200) {
                setSingleService(service?.data)
            }
        } catch (e) {
            ErrorMsg(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        handleGetSingleService();
    }, [params])
    return (
        <div>
            <BreadCrumb mainTitle={"الرئيسية "} secondTitle={"خدماتنا"} thirdTitle={`${singleService?.name}`} />
            {
                singleService ? (
                    <ServiceComponent reader={singleService?.description} imageCover={singleService?.coverImage} readKey={false} />
                ) : (<Skeleton />)
            }
        </div>
    )
}

export default DetailsService
