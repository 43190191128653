import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Register_New_User, Register_New_User_Admin } from '../../../actions/signin-up';
import { ErrorMsg, success } from '../../shared/Modules/Toast';
import { HomePage } from '../../../translation/trans';
const AddEmployee = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const hnadleClose = () => setShow(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [jop, setJop] = useState("");
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState("")
    const dispatch = useDispatch();
    const handlePhone = (valuePhone) => {
        setPhone(valuePhone)
    }
    const handleRegister = async () => {
        setLoading(true)
        try {
            const resRegister = await dispatch(Register_New_User_Admin({
                name: name,
                email: email,
                password: pass,
                role: role,
                phone: `+${phone}`,
                country: country,
                address: address,
                job: jop
            }))
            console.log(resRegister)
            if (resRegister?.status === 201) {
                success(`تم التسجيل بنجاح`);
                hnadleClose();
            }

        } catch (e) {
            if (Array.isArray(e?.response?.data?.errors)) {
                e.response.data.errors.forEach(error => {
                    ErrorMsg(error.msg || "هناك مشكله")
                })
            } else {
                ErrorMsg("هناك مشكله")
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <button className='btn login' onClick={handleShow}>اضافة موظف</button>
            <Modal show={show} onHide={hnadleClose} centered dir={dir}>
                <Modal.Body>
                    <form>
                        <div className="">
                            <div className='text-center'>
                                {/* <h4>{HomePage.welcome[lang]} <span style={{ color: "#4D78C3" }}>Osbash</span></h4> */}

                            </div>
                            <div>
                                <div className="mt-1">
                                    <label>{HomePage.email[lang]}</label>
                                    <input type="email" name="email" id="email" className=' form-control' value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.username[lang]}</label>
                                    <input type="text" name="name" id="name" className=' form-control' value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.password[lang]}</label>
                                    <input type="password" name="pass" id="pass" className=' form-control' value={pass}
                                        onChange={(e) => setPass(e.target.value)}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.phone[lang]}</label>
                                    <PhoneInput
                                        country={'eg'}
                                        // className="form-control"
                                        style={{ width: "100%" }}
                                        value={phone}
                                        onChange={(phone) => handlePhone(phone)}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.country[lang]}</label>
                                    <input type="text" name="country" id="country" className=' form-control' value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.address[lang]}</label>
                                    <input type="text" name="address" id="address" className=' form-control' value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.role[lang]}</label>
                                    <select type="text" name="role" id="role" className=' form-control' value={role} onChange={(e) => setRole(e.target.value)}>
                                        <option hidden>Choose Role</option>
                                        <option value="employee">employee </option>
                                        <option value="client">client</option>
                                    </select>
                                </div>
                                <div className="mt-1">
                                    <label>{HomePage.job[lang]}</label>
                                    <input type="text" name="jop" id="jop" className=' form-control' value={jop}
                                        onChange={(e) => setJop(e.target.value)}
                                    />
                                </div>
                                <div className=" mt-3">
                                    <button className="btn" style={{ backgroundColor: "#4B76C0", color: "white" }}
                                        onClick={handleRegister}
                                        disabled={loading}
                                    >
                                        {loading ? HomePage.loading[lang] : HomePage.register[lang]}
                                    </button>
                                    <button className="btn mx-2" style={{ border: "1px solid #4B76C0", color: "#4B76C0" }}
                                        onClick={hnadleClose}
                                        disabled={loading}
                                    >
                                        {HomePage.cancel[lang]}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddEmployee
