import React, { useEffect, useState } from 'react'
import profile from "../../../assests/profile.png"
// import { HomePage } from '../../../translation/trans'
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { HomePage } from '../../../translation/trans';
import { updateProfile } from '../../../utils/API';
import { ErrorMsg, success } from '../../../components/shared/Modules/Toast';
import Headerdashboard from './Headerdashboard';
const HeaderClient = () => {
    const [user, setUser] = useState(null);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    useEffect(() => {

        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
            } catch (error) {
                console.log("Invalid user data in localStorage");
                localStorage.removeItem("user");
            }
        }
    }, []);
    const handlePhone = (valuePhone) => {
        setPhone(valuePhone)
    }

    const [fName, setFName] = useState(user?.name);
    const [email, setEmail] = useState(user?.email);
    const [confirmEmail, setConfirmEmail] = useState(user?.email);
    const [phone, setPhone] = useState(user?.phone);
    const [country, setCountry] = useState(user?.country);
    const [address, setAddress] = useState(user?.address);
    const [job, setJob] = useState(user?.job);
    const [role, setRole] = useState(user?.role);
    const [loading, setLoading] = useState(false);
    const [pass, setPass] = useState("")
    useEffect(() => {
        setFName(user?.name)
        setEmail(user?.email)
        setConfirmEmail(user?.email)
        setPhone(user?.phone)
        setCountry(user?.country)
        setAddress(user?.address)
        setJob(user?.job)
        setRole(user?.role)
    }, [user])

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const updatedPhone = phone !== user?.phone ? `+${phone}` : phone;
            const res = await updateProfile({
                name: fName,
                email: email,
                phone: updatedPhone,
                password: pass,
                role: role,
                country: country,
                address: address,
                job: job,
            })
            console.log(res)
            if (res?.status === 200) {
                success("Updated profile successfully")
                const updatedUser = {
                    ...user,
                    name: fName,
                    email: email,
                    phone: updatedPhone,
                    country: country,
                    address: address,
                    job: job,
                    role: role,
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                setUser(updatedUser);
            }
        } catch (e) {

            if (Array.isArray(e?.response?.data?.message)) {
                e.response.data.message.forEach(error => {
                    ErrorMsg(error || "هناك مشكله")
                })
            } else {
                ErrorMsg("هناك مشكله")
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className='mt-5'>
                <Headerdashboard/>
                <div>
                    <form>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='mb-2'>{HomePage.name[lang]}</label>
                                <input type="text" className='form-control' value={fName} onChange={(e) => setFName(e.target.value)} />
                            </div>
                            {/* <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='mb-2'>{HomePage.name[lang]}</label>
                                <input type="text" className='form-control' value={fName} onChange={(e) => setFName(e.target.value)} />
                            </div> */}
                        </div>
                        <div className='row justify-content-between align-items-center mt-5'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='mb-2'>{HomePage.email[lang]}</label>
                                <input type="text" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='mb-2'>{HomePage.confirmEmail[lang]}</label>
                                <input type="text" className='form-control' value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                            </div>
                        </div>
                        <div>
                            <label className='mb-3'>{HomePage.phone[lang]}</label>
                            <PhoneInput
                                country={'eg'}
                                className="inputs"
                                style={{ width: "100%" }}
                                value={phone}
                                onChange={(phone) => handlePhone(phone)}
                            />
                        </div>
                        <div>
                            <label className='mb-2'>{HomePage.job[lang]}</label>
                            <input type="text" className='form-control' value={job} onChange={(e) => setJob(e.target.value)} />
                        </div>
                        <div>
                            <label className='mb-2'>{HomePage.password[lang]}</label>
                            <input type="password" className='form-control' value={pass} onChange={(e) => setPass(e.target.value)} placeholder='اكتب كلمة السر القديمه او كلمة سر جديده' />
                        </div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='mb-2'>{HomePage.country[lang]}</label>
                                <input type="text" className='form-control' value={country} onChange={(e) => setCountry(e.target.value)} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='mb-2'>{HomePage.address[lang]}</label>
                                <input type="text" className='form-control' value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>

                        </div>
                        <div>
                            <label className='mb-3'>{HomePage.abouttYou[lang]}</label>
                            <textarea type="text" className='form-control' style={{ resize: "none" }} />
                        </div>
                        <div className='mt-3 ' style={{ textAlign: "left" }}>
                            <button className='btn' onClick={handleUpdateProfile} disabled={loading} style={{ backgroundColor: "#4973BC", color: "white" }}>{!loading ? HomePage.save[lang] : "loading..."}</button>
                            <button className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>{HomePage.cancel[lang]}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default HeaderClient
