import React, { useEffect, useState } from 'react'
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Del_project, Update_project } from '../../../actions/projectsAction';
import { MdModeEditOutline } from "react-icons/md";

import { Modal } from 'react-bootstrap';
import { ErrorMsg, success } from '../../shared/Modules/Toast';
import { getAllClients, urlBase } from '../../../utils/API';
import axios from 'axios';
const ModalUpdate = ({ show, setShow, pro }) => {

    const [image, setImage] = useState("");

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return dateString.split('T')[0];
    }

    const [stages, setStages] = useState(pro?.stages || [{
        name: '',
        description: '',
        date: '',
        status: 'completed'
    }]);

    const [paymentStages, setPaymentStages] = useState(pro?.paymentStages || [{
        name: '',
        description: '',
        price: '',
        discount: '',
        date: '',
        status: 'unpaid'
    }]);


    // Add the same handlers from AddProjectModal
    const handleAddStage = () => {
        setStages([...stages, {
            name: '',
            description: '',
            date: '',
            status: 'completed'
        }]);
    };

    const handleAddPaymentStage = () => {
        setPaymentStages([...paymentStages, {
            name: '',
            description: '',
            price: '',
            discount: '',
            date: '',
            status: 'unpaid'
        }]);
    };

    const handleStageChange = (index, field, value) => {
        const newStages = [...stages];
        newStages[index][field] = value;
        setStages(newStages);
    };

    const handlePaymentStageChange = (index, field, value) => {
        const newPaymentStages = [...paymentStages];
        if (field === 'price' || field === 'discount') {
            newPaymentStages[index][field] = Number(value);
        } else {
            newPaymentStages[index][field] = value;
        }
        setPaymentStages(newPaymentStages);
    };


    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/uploads/single`,
            data: formData,
        })
    }



    // const [show, setShow] = useState(false);
    const serviceData = useSelector((state) => state.serviceReducer);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [name, setName] = useState(pro?.name);
    const [desc, setDesc] = useState(pro?.description);
    const [status, setStatus] = useState(pro?.status);
    const [startDate, setStartDate] = useState(formatDate(pro?.startDate));
    const [endDate, setEndDate] = useState(formatDate(pro?.endDate));
    const [deadLine, setDeadLine] = useState(formatDate(pro?.deadline));
    const [coverImage, setCoverImage] = useState("");
    const [service, setService] = useState(pro?.service);
    const [loading, setLoading] = useState(false)
    const [client, setClient] = useState("");
    useEffect(() => {
        setName(pro?.name);
        setDesc(pro?.description);
        setStatus(pro?.status);
        setStartDate(formatDate(pro?.startDate));
        setEndDate(formatDate(pro?.endDate));
        setDeadLine(formatDate(pro?.deadline));
        setCoverImage(pro?.coverImage);
        setService(pro?.service);
        setClient(pro?.client?._id);
        setStages(pro?.stages || [{
            name: '',
            description: '',
            date: '',
            status: 'completed'
        }]);
        setPaymentStages(pro?.paymentStages || [{
            name: '',
            description: '',
            price: '',
            discount: '',
            date: '',
            status: 'unpaid'
        }]);

    }, [pro])

    const dispatch = useDispatch();

    const handleAddProject = async () => {
        try {
            setLoading(true);
            let imageUrl = pro?.coverImage; 
        if (image) {
            const uploadResponse = await uploadingFileLocally(image);
            imageUrl = uploadResponse.data.filename; 
        }
            const res = await dispatch(Update_project(
                {
                    name: name,
                    description: desc,
                    status: status,
                    startDate: startDate,
                    endDate: endDate,
                    deadline: deadLine,
                    coverImage: imageUrl,
                    client: client,
                    // stages: stages,
                    clientComment: "Client Comment",
                    team: [
                        "66ebf1698e5c5964fabd12ff"
                    ],
                    service: service,
                    // paymentStages: paymentStages
                },
                pro?._id
            ))
            if (res?.status === 200) {
                handleClose();
                success("تم التعديل بنجاح");
            }
        } catch (e) {
            if (e?.response?.data?.message) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.forEach(msg => {
                        ErrorMsg(msg);
                    });
                } else {
                    ErrorMsg(e.response.data.message);
                }
            }

        } finally {
            setLoading(false);
        }
    }


    const [clients, setClinets] = useState([]);
    useEffect(() => {
        const handleGetUsers = async () => {
            try {
                const res = await getAllClients();
                console.log(res)
                if (res?.status === 200) {
                    // console.log(res?.data);
                    setClinets(res?.data)
                }
            } catch (e) {
                console.log(e)
            }
        }
        handleGetUsers();
    }, [])



    return (
        <div>
            { }

            <MdModeEditOutline onClick={handleShow} size={"25px"} color="white" />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="">
                        <div>
                            <div>
                                <label>اسم المشروع</label>
                                <input type="text" name="name" id="name" className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>وصف المشروع</label>
                                <input type="text" name="name" id="name" className="form-control"
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                />
                            </div>
                            <div>
                            <label>صورة المشروع</label>
                            <input type="file" name="fileImage" id="fileImage" className="form-control"
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    const url = window.URL.createObjectURL(event.target.files[0]);
                                }}
                            />
                        </div>
                            <div>
                                <label>تاريخ البدايه</label>
                                <input type="date" name="name" id="name" className="form-control"
                                    onChange={(e) => setStartDate(e.target.value)} value={startDate}
                                />
                            </div>
                            <div>
                                <label>تاريخ النهايه</label>
                                <input type="date" name="price" id="price" className="form-control"
                                    onChange={(e) => setEndDate(e.target.value)} value={endDate}
                                />
                            </div>
                            <div>
                                <label>الوقت المحدد لانهاء المشروع</label>
                                <input type="date" name="price" id="price" className="form-control"
                                    onChange={(e) => setDeadLine(e.target.value)} value={deadLine}
                                />
                            </div>


                            <div>
                                <label>حالة العمل</label>
                                <select className="form-control" onChange={(e) => setStatus(e.target.value)} value={status}>
                                    <option hidden>Status</option>
                                    <option value={"inprogress"}>inprogress</option>
                                    <option value={"waiting"}>waiting</option>
                                    <option value={"completed"}>completed</option>
                                </select>
                            </div>
                            <div>
                                <label>العميل
                                    <span style={{ color: 'red' }}>*</span>
                                </label>

                                <select

                                    className="form-control" onChange={(e) => {
                                        setClient(e.target.value)


                                    }

                                    } value={client}>
                                    <option hidden>Client</option>
                                    {clients.map((client) => (
                                        <option key={client._id} value={client._id}>
                                            {client.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div>
                                <label>دعم الفريق</label>
                                <select className="form-control" >
                                    <option hidden>Status</option>
                                    <option value={"active"}>Active</option>
                                    <option value={"completed "}>completed </option>
                                </select>
                            </div>
                            <div>
                                <label>نوع الخدمه</label>
                                <select className="form-control" onChange={(e) => setService(e.target.value)} value={service}>
                                    <option hidden>Service Type</option>
                                    {
                                        serviceData?.map((serviceData) => {
                                            return (
                                                <option key={serviceData?._id} value={serviceData?._id}>{serviceData.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                            <div className="stages-section mt-4">
                                <h5>المراحل غير قابل للتعديل</h5>
                                {stages.map((stage, index) => (
                                    <div key={index} className="stage-item border p-3 mb-3">
                                        <div className="form-group">
                                            <label>اسم المرحلة</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={stage.name}
                                                readOnly
                                                disabled
                                                onChange={(e) => handleStageChange(index, 'name', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>الوصف</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                disabled
                                                value={stage.description}
                                                onChange={(e) => handleStageChange(index, 'description', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>التاريخ</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                readOnly
                                                disabled
                                                value={formatDate(stage.date)}
                                                onChange={(e) => handleStageChange(index, 'date', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>الحالة</label>
                                            <select
                                                className="form-control"
                                                disabled
                                                value={stage.status}
                                                onChange={(e) => handleStageChange(index, 'status', e.target.value)}
                                            >
                                                <option value="waiting">waiting</option>
                                                <option value="inprogress">inprogress</option>
                                                <option value="completed">completed</option>
                                            </select>
                                        </div>
                                    </div>
                                ))}
                                <button type="button" disabled className="btn btn-secondary" onClick={handleAddStage}>
                                    + إضافة مرحلة جديدة
                                </button>
                            </div>

                            {/* Payment Stages Section */}
                            <div className="payment-stages-section mt-4">
                                <h5>مراحل الدفع غير قابل للتعديل</h5>
                                {paymentStages.map((paymentStage, index) => (
                                    <div key={index} className="payment-stage-item border p-3 mb-3">
                                        <div className="form-group">
                                            <label>اسم مرحلة الدفع</label>
                                            <input
                                                type="text"
                                                readOnly
                                                disabled
                                                className="form-control"
                                                value={paymentStage.name}
                                                onChange={(e) => handlePaymentStageChange(index, 'name', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>الوصف</label>
                                            <input
                                                readOnly
                                                disabled
                                                type="text"
                                                className="form-control"
                                                value={paymentStage.description}
                                                onChange={(e) => handlePaymentStageChange(index, 'description', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>السعر</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={paymentStage.price}
                                                readOnly
                                                disabled
                                                onChange={(e) => handlePaymentStageChange(index, 'price', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>الخصم</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                readOnly
                                                disabled
                                                value={paymentStage.discount}
                                                onChange={(e) => handlePaymentStageChange(index, 'discount', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>التاريخ</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                readOnly
                                                disabled
                                                value={formatDate(paymentStage.date)}
                                                onChange={(e) => handlePaymentStageChange(index, 'date', e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>حالة الدفع</label>
                                            <select
                                                className="form-control"
                                                disabled
                                                value={paymentStage.status}
                                                onChange={(e) => handlePaymentStageChange(index, 'status', e.target.value)}
                                            >
                                                <option value="paid">paid</option>
                                                <option value="unpaid">unpaid</option>
                                            </select>
                                        </div>
                                    </div>
                                ))}
                                <button type="button" className="btn btn-secondary" onClick={handleAddPaymentStage} disabled>
                                    + إضافة مرحلة دفع جديدة
                                </button>
                            </div>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mt-4" style={{ textAlign: "left" }}>
                        <button onClick={handleClose} className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>
                            الغاء
                        </button>
                        <button disabled={loading} className='btn' onClick={handleAddProject} style={{ backgroundColor: "#4973BC", color: "white" }}>
                            {loading ? "تحميل" : "اضافه"}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalUpdate
