import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash } from 'react-icons/fa';
import { Delete_Testimonial } from '../../../actions/testimonialAct';

const ShowTestimonial = ({ show, handleClose, project }) => {
    const testimonials = useSelector((state) => state.testimonialRed);

    const filteredTestimonials = testimonials.filter(
        (testimonial) => testimonial.project === project?._id
    );

    const dispatch = useDispatch();

    // State لإدارة التأكيد
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedTestimonial, setSelectedTestimonial] = useState(null);

    // فتح المودال لتأكيد الحذف
    const openConfirmModal = (testimonialId) => {
        setSelectedTestimonial(testimonialId);
        setShowConfirmModal(true);
    };

    // إغلاق المودال دون حذف
    const closeConfirmModal = () => {
        setSelectedTestimonial(null);
        setShowConfirmModal(false);
    };

    // تنفيذ الحذف
    const handleDelete = async () => {
        try {
            await dispatch(Delete_Testimonial(selectedTestimonial));
            closeConfirmModal();
        } catch (e) {
            console.error("Failed to delete:", e);
        }
    };

    return (
        <div>
            {/* Testimonials Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Testimonials for {project?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {filteredTestimonials.length > 0 ? (
                        filteredTestimonials.map((testimonial) => (
                            <div
                                key={testimonial?._id}
                                className="testimonial-item mb-3 p-3 font"
                                style={{
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '8px',
                                    backgroundColor: '#f9f9f9',
                                    position: 'relative'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h5 style={{ color: testimonial?.active ? 'green' : 'red' }}>
                                        {testimonial?.active ? 'Active' : 'Inactive'}
                                    </h5>
                                    <FaTrash
                                        size={18}
                                        style={{ cursor: 'pointer', color: 'red' }}
                                        onClick={() => openConfirmModal(testimonial?._id)}
                                    />
                                </div>
                                <p style={{ margin: '10px 0', fontWeight: 'bold' }}>
                                    <strong>Content:</strong> {testimonial?.review}
                                </p>
                                <p>
                                    <strong>Rating:</strong>{' '}
                                    <span style={{ color: '#ffc107' }}>{'★'.repeat(testimonial?.rate)}</span>
                                </p>
                            </div>
                        ))
                    ) : (
                        <p>No testimonials available for this project.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={closeConfirmModal}>
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this testimonial?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={closeConfirmModal}>
                        Cancel
                    </button>
                    <button className="btn btn-danger" onClick={handleDelete}>
                        OK
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ShowTestimonial;
